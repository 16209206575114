import React from "react";

import Layout from "../components/common/Layout";
import SEO from "../components/common/SEO";
import NewsletterRow from "../components/landingPage/NewsletterRow";

import { Linkedin, Twitter, Instagram, Facebook } from "react-feather";

import "./contact.css";

const IndexPage = () => {
  return (
    <main>
      <Layout>
        <SEO title="Potwierdzenie Subskrybcji - Hazay Bikes" />
        <div
          style={{
            backgroundColor: "rgb(36, 36, 36)",
            width: "100%",
            paddingTop: "80px",
          }}
        >
          <div
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              maxWidth: "52.5rem",
              padding: `3rem 1.3125rem 7.625rem`,
              flex: 1,
            }}
          >
            <h1 style={{ margin: 0, marginBottom: "3rem", color: "white" }}>
              {"Sukces! Potwierdzamy subskrybcje."}
            </h1>
            <p style={{ color: "white" }}>
              Dzięki za zainteresowanie Hazayem! Odezwiemy się do Ciebie
              wkrótce.
            </p>
            <p style={{ color: "white" }}>
              Za chwilę dostaniesz mail potwierdzający zapisanie się na naszą
              listę. Sprawdź czy nie wpadł do spamu i dodaj nasz adres do listy
              zaufanych odbiorców, by w przyszłości nie ominęły Cię żadne newsy
              od Hazaya.
            </p>
            <p style={{ color: "white" }}>
              {"Stay tuned!"} <br />
              {"Hazay Team"}
            </p>
          </div>
        </div>
      </Layout>
    </main>
  );
};

const socialLinks = () => [
  <a
    href="https://www.linkedin.com/company/hazay-bikes/"
    target="_blank"
    rel="noopener noreferrer"
    className="contact-social-icon"
  >
    <Linkedin />
  </a>,
  <a
    href="https://twitter.com/BikesHazay"
    target="_blank"
    rel="noopener noreferrer"
    className="contact-social-icon"
  >
    <Twitter />
  </a>,
  <a
    href="https://www.instagram.com/hazay_bikes/"
    target="_blank"
    rel="noopener noreferrer"
    className="contact-social-icon"
  >
    <Instagram />
  </a>,
  <a
    href="https://www.facebook.com/hazaybikes"
    target="_blank"
    rel="noopener noreferrer"
    className="contact-social-icon"
  >
    <Facebook />
  </a>,
];

export default IndexPage;
